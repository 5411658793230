import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PostExcerpt from "../components/postExcerpt"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Pager from "../components/pager"

const BlogArchive = ({ data, pageContext, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Archive" />
      <div style={{paddingBottom: rhythm(1.5)}}>
        <h1 style={{ marginTop: rhythm(1.5), marginBottom: 0 }}>
          Archive (p. {pageContext.humanPageNumber})
        </h1>
        {posts.map(({ node, index }) => {
          return <PostExcerpt post={ node } key={ index } />
        })}
      </div>
      <hr style={{ marginBottom: rhythm(0.25) }} />
      <div
        style={{
          display: `block`,
          marginBottom: rhythm(1),
        }}
      >
        <small>Archive (p. {pageContext.humanPageNumber})</small>
      </div>
      <Pager pageContext={pageContext} />
    </Layout>
  )
}

export default BlogArchive

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {fileAbsolutePath: {regex: "\\/blog/"}}
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`
